import React, { useState }      from 'react';
import { Form, Row, Col }       from 'react-bootstrap';
import { toast }                from 'react-toastify';
import { useKeycloak }          from "@react-keycloak/web";

import { fetchDataWithAccount } from '../fetchData';
import { useAccount }           from './context/useAccount';

export default function Settings(props) {
  const defaultSendDirect = Object.prototype.hasOwnProperty.call(props.account, 'send_invoice_directly_to_client') ?
    props.account.send_invoice_directly_to_client : false;
  const [sendDirect, setSendDirect] = useState(defaultSendDirect ? 'direct' : 'indirect');
  const { setAccount } = useAccount();
  const { keycloak } = useKeycloak();

  const handleChange = (evt) => {
    setSendDirect(evt.target.value);
    const send_invoice_directly_to_client = evt.target.value === 'direct' ? true : false;

    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/emailing`,
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      onSuccess: () => {
        if (send_invoice_directly_to_client) {
          toast.success('Invoices are mailed directly to the client.');
        } else {
          toast.success('Invoices are mailed to a FlexCar account.');
        }
      }
    }, setAccount);
  }

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={2}>
          Invoices
        </Form.Label>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Send directly to client's email"
            value="direct"
            checked={sendDirect === "direct"}
            onChange={handleChange}
          />
          <Form.Check
            type="radio"
            label="Send to a FlexCar account"
            value="indirect"
            checked={sendDirect === "indirect"}
            onChange={handleChange}
          />
        </Col>
      </Form.Group>
    </Form>
  );
}