import React, { useState }                                                             from 'react';
import { toast }                                                                       from 'react-toastify';
import { Navbar, Button, Modal, Dropdown, DropdownButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useKeycloak }                                                                 from "@react-keycloak/web";

import FormFrame     from './form_frame/FormFrameWrapper';
import empty         from './externals/empty.json';
import { fetchData } from './fetchData';

export default function TopBar(props) {

  return (
    <Navbar bg="light" fixed="bottom">
      <AddAccountModal handleArchiveButton={props.handleArchiveButton} showArchive={props.showArchive}/>
    </Navbar>
  );
}

function AddAccountModal(props) {

  const [openModal, setOpenModal] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { keycloak } = useKeycloak();

  const createReportEmail = method => {
    setButtonsDisabled(true);

    fetchData({
      url: method,
      onSuccess: () => toast.success("Email sent."),
      onError: () => toast.error("Email could not be sent.", { autoClose: false }),
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
    }).finally(() => setButtonsDisabled(false));
  }

  return (
    <>
      <Button
        variant="outline-success"
        onClick={() => setOpenModal(true)}
        backdrop={'static'}
      >
        Add new account
      </Button>

      <ButtonToolbar className="justify-content-end">
        <DropdownButton variant="outline-primary" title="Raw" drop="up" as={ButtonGroup}>
          <Dropdown.Item href="/invoices">Invoices</Dropdown.Item>
          <Dropdown.Item href="/payments">Payments</Dropdown.Item>
        </DropdownButton>
        <Button variant="outline-primary" onClick={() => createReportEmail('/api/report/daily-digest')}
                disabled={buttonsDisabled}>
          Create daily digest
        </Button>
        {/*<Button variant="primary" onClick={() => createReportEmail('/api/report/daily-payments')} disabled={buttonsDisabled}>
          Create today's payment list
        </Button>*/}
        <Button variant={props.showArchive === true ? "outline-success" : "outline-secondary"}
                onClick={props.handleArchiveButton}>
          {props.showArchive === true ? "Back to active" : "Switch to archive"}
        </Button>
      </ButtonToolbar>

      <Modal show={openModal} onHide={() => setOpenModal(false)} backdrop={'static'} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add new account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormFrame
            account={empty}
            method="insert"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setOpenModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
