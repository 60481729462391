import React, { useState }                              from 'react';
import { Table, Button, ButtonToolbar, Spinner, Badge,Modal } from 'react-bootstrap';
import moment                                           from 'moment';
import { toast }                                        from 'react-toastify';
import {
  formatRentPeriodFromContract
}                                                       from '@flexcar/dateutils';

import EndContract                         from './EndContract';
import { formatCurrency }                  from '../CurrencyFormatter';
import { fetchDataWithAccount, fetchData } from '../fetchData';
import { useAccount }                      from './context/useAccount';
import { NewInvoiceModal }                 from './NewInvoiceModal';
import { useKeycloak }                     from "@react-keycloak/web";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


export function Contracts(props) {
  const [loading, setLoading] = useState(false);
  const { setAccount, setInvoices } = useAccount();
  const { keycloak } = useKeycloak();

 
  
  const deleteContract = (contract) => {
    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/contract/${contract._id}`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      method: 'DELETE'
    }, setAccount)
    hideModal()
  };

  const handleNewInvoice = (contract) => {
    if (loading === true) return;
    setLoading(true);

    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/invoice/${contract._id}`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      method: 'POST',
      onSuccess: ({ message }) => toast.success(message)
    }, setAccount)
      .then(() => fetchData({
        url: `/api/invoice/account/${props.account._id}`,
        onSuccess: data => setInvoices(data.invoices),
        headers: {
          'Authorization': `Bearer ${keycloak.token}`,
        },
      }))
      .finally(() => setLoading(false));
  };

  const handleCarDelivery = contract => {
    if (loading === true) return;
    setLoading(true);

    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/contract/${contract._id}/delivered`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      method: 'POST',
      onSuccess: () => toast.success('Car delivered.')
    }, setAccount).finally(() => setLoading(false));
  };

  const handleFlexDepositInvoice = contract => {
    if (loading === true) return;
    setLoading(true);

    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/custom/invoice/${contract._id}`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      body: {},
      onSuccess: () => toast.success('FlexDeposit invoiced.')
    }, setAccount).then(() => fetchData({
      url: `/api/invoice/account/${props.account._id}`,
      onSuccess: data => setInvoices(data.invoices),
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
    })).finally(() => setLoading(false));
  }

  const handlePDFDownload = (accountId, contractId) => {
    setLoading(true);
    fetch(`/api/account/${accountId}/contract/${contractId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
    }).then(res => {
      if (!res.ok) {
        throw new Error(res);
      }
      res.arrayBuffer().then(b => {
        const blob = new Blob([b], { type: 'application/pdf' })
        const link = document.createElement('a');
        const match = res.headers.get('Content-Disposition').match(/filename="(.*).pdf"/);
        link.href = window.URL.createObjectURL(blob);
        link.download = `${match[1]}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }).catch(e => console.log(e))
        .finally(() => setLoading(false))
    }).catch(err => {
      toast.error('Error. PDF could not be generated.', {
        autoClose: false
      });
    });
  }
  
  let vat_rate;
  if (props.account.billing_type === "company" && props.account.company.reverse_charge_applies === true) {
    vat_rate = 0;
  } else if ((props.account.billing_type === "company" || props.account.billing_type === "selfemployed") && props.account.locale.split('-')[1] === 'gr') {
    const extraVat = props.account.company.extra_vat ?? 0;
    vat_rate = 24 + (extraVat * 100);
  } else if (props.account.locale.split('-')[1] === 'cy') {
    vat_rate = 19;
  } else {
    vat_rate = 24;
  }

  const [isOpen, setIsOpen] =useState(null);
  const [model,setModel]= useState(null);
  const [licensePlate,setLicensePlate]= useState(null)
  const [Contract,setContract]= useState(null)
  
  
  
  const showModal = (contract) => {
    setIsOpen(true);
    setLicensePlate(contract.car_license_plate)
    setModel(contract.car_model)
    setContract(contract)
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  
  const deleteModal =  () => {
    return (
      <Modal show={isOpen} onHide={hideModal} >
        <Modal.Header>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete Model : {model} with Licence Plate : {licensePlate} ? </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>Cancel</Button>
          <Button variant="danger" onClick={() => deleteContract(Contract)}>DELETE</Button>
        </Modal.Footer>
      </Modal>
    )
    
  }
  
  
  return (
    <>
      <NewInvoiceModal/>
      <Table responsive>
        <thead>
        <tr>
          <th>Model</th>
          <th>License plate</th>
          <th>Installment<br/>
            {`(incl. ${vat_rate}% VAT)`}
          </th>
          <th>Delivery date</th>
          <th>Return date</th>
          <th>Due day</th>
          <th>Next period</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {props.account.contracts.map((contract, k) => {
          const { date_span, to } = formatRentPeriodFromContract(contract);
          return (
            <tr key={k}>
              <td>
                {contract.car_model}
                {contract.flexdeposit === 0 ? <Badge variant="warning">No FlexDeposit</Badge> : null}
              </td>
              <td>
                {contract.car_license_plate}
              </td>
              <td>
                {formatCurrency(contract.installment * (1 + vat_rate / 100))}
              </td>
              <td>
                {moment(contract.car_delivered_date).format('DD.MM.YY')}
              </td>
              <td>
                {contract.contract_end_date ? moment(contract.contract_end_date).format('DD.MM.YY')
                  : contract.car_delivered === false ? <Badge variant="warning">Waiting</Badge>
                    : <Badge variant="success">Active</Badge>}
              </td>
              <td>
                {contract.installment_day}
              </td>
              <td>
                {date_span}
              </td>
              <td>
                <ButtonToolbar>
                  {contract.locked ?
                    <>
                      <Button
                        variant="success"
                        onClick={() => handleNewInvoice(contract)}
                        disabled={loading}
                      >
                        New installment invoice
                      </Button>
                      <EndContract
                        contract_id={contract._id}
                        billed_until_date={contract.billed_until_date}
                        rent_period_end={to.toDate()}
                      />
                    </>
                    :
                    <>
                      {contract.flexdeposit_issued !== true && contract.flexdeposit !== 0 ?
                        <Button
                          variant="danger"
                          onClick={() => handleFlexDepositInvoice(contract)}
                          disabled={loading}
                        >
                          Invoice FlexDeposit
                        </Button>
                        :
                        <Button
                          variant="secondary"
                          onClick={() => handleCarDelivery(contract)}
                          disabled={loading}
                        >
                          Deliver car
                        </Button>
                      }
                      <Button
                        variant="success"
                        onClick={event => {
                          event.preventDefault();
                          handlePDFDownload(props.account._id, contract._id);
                        }}
                        disabled={loading}
                      >
                        { loading ? 'loading...' : 'Create contract' }
                      </Button>
                      {contract.car_delivered === false && props.account.contracts.length > 1 ?
                        <>
                        <Button
                          variant="danger"
                          onClick={()=>{showModal(contract)}}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        </>
                        :
                        null
                      }
                    </>
                  }
                  {loading === true ?
                    <Button variant="sucess" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                    : null}
                </ButtonToolbar>
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
      { isOpen?deleteModal():null}
    </>
  );
}