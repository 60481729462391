import React                                       from 'react';
import { Formik, FieldArray }                      from 'formik';
import { Button, Form, Row, Col, Card, Accordion } from 'react-bootstrap';
import { toast }                                   from 'react-toastify';
import moment                                      from 'moment';
import { FontAwesomeIcon }                         from '@fortawesome/react-fontawesome';
import { faChevronRight }                          from '@fortawesome/free-solid-svg-icons';
import { useKeycloak }                             from "@react-keycloak/web";

import { fetchData, fetchDataWithAccount } from '../fetchData';
import { useAccount }                      from '../account/context/useAccount';

import '../accountstable.css';

// TODO import from file
const config = {
  locales: ['el-gr', 'en-gr', 'el-cy', 'en-cy'],
  vehicleTypes: [
    {
      key: 'car',
      text: 'Car'
    },
    {
      key: 'motorcycle',
      text: 'Motorcycle'
    },
  ],
  branches: {
    gr: [
      {
        key: 'flexcar',
        text: 'FlexCar AE'
      },
      {
        key: 'premium',
        text: 'Premium Car Leases'
      }
    ],
    cy: [
      {
        key: 'flexcar',
        text: 'FlexCar Rentals Ltd'
      },
      {
        key: 'zampus',
        text: 'Zampus Trading Ltd'
      },
      {
        key: 'eliosanas',
        text: 'Eliosanas Ltd'
      }
    ]
  }
};

export default function FormFrame(props) {
  const { setAccount } = useAccount();
  const { keycloak } = useKeycloak();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const via_formik = {
      ...values,
      contracts: values.contracts.map(el => {
        delete el.addedInThisSession;
        return {
          ...el,
          car_delivered_date: new Date(el.car_delivered_date)
        }
      })
    };

    if (props.method === "update") {
      fetchDataWithAccount({
        url: `/api/account/${props.account._id}`,
        headers: {
          'Authorization': `Bearer ${keycloak.token}`,
        },
        method: 'PUT',
        body: via_formik,
      }, setAccount).finally(() => setSubmitting(false));
    } else {
      fetchData({
        url: '/api/account',
        headers: {
          'Authorization': `Bearer ${keycloak.token}`,
        },
        method: 'POST',
        body: via_formik,
        onSuccess: () => toast.success('Account successfully added!'),
        onWarning: message => toast.warning(message, { autoClose: false }),
        onError: message => toast.error(message, { autoClose: false })
      }).finally(() => setSubmitting(false));
    }

  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        ...props.account,
        contracts: props.account.contracts.map(el => ({
          ...el,
          car_delivered_date:(props.method === "insert") ? moment().format('YYYY-MM-DD'):moment.utc(el.car_delivered_date).format('YYYY-MM-DD'),
        }))
      }}
    >
      {({ handleSubmit, handleChange, values, isSubmitting, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Button variant="secondary" onClick={props.handleExternals} style={{
            marginTop: '10px'
          }}>
            Add from external
          </Button>
          <hr/>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Client ID</Form.Label>
                <Form.Control type="text"
                              name="client_id"
                              value={values.client_id}
                              disabled={props.hasInvoices}
                              onChange={handleChange}
                              pattern={`^C${values.locale.split('-')[1].toUpperCase()}[0-9]{2}[A-Z][0-9]{3}$`}
                              required
                />
                <Form.Text className="text-muted">
                  Format example: CGR19A001
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Billing type</Form.Label>
                <Form.Control as="select"
                              name="billing_type"
                              disabled={props.hasInvoices}
                              value={values.billing_type}
                              onChange={handleChange}
                >
                  <option value="individual">individual</option>
                  <option value="company">company</option>
                  <option value="selfemployed">self-employed</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Locale</Form.Label>
                <Form.Control as="select"
                              name="locale"
                              disabled={props.hasInvoices}
                              value={values.locale}
                              onChange={e => {
                                setFieldValue('locale', e.target.value);
                                // reset branch when changing locale
                                setFieldValue('branch', 'flexcar');
                                if (e.target.value.split('-')[1] !== 'gr') {
                                  setFieldValue('company.extra_vat', 0);
                                }
                              }}
                >
                  {config.locales.map((el, idx) => {
                    return <option key={idx} value={el}>{el}</option>;
                  })}
                </Form.Control>
                <Form.Text className="text-muted">
                  Language - Operating country
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Branch</Form.Label>
                <Form.Control as="select"
                              name="branch"
                              value={values.branch}
                              disabled={props.hasInvoices}
                              onChange={handleChange}
                >
                  {config.branches[values.locale.split('-')[1].toLowerCase()].map((el, idx) => {
                    return <option key={idx + 1} value={el.key}>{el.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Card>
            <Card.Header as="h4">User</Card.Header>
            <Card.Body>
              <Row>
                <Col>

                  <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="text"
                                  name="user.last_name"
                                  value={values.user.last_name}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Text className="text-muted">
                      Please do not capitalize names (SOPHOCLES {'>>'} Sophocles).
                    </Form.Text>
                  </Form.Group>

                </Col>
                <Col>

                  <Form.Group>
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text"
                                  name="user.first_name"
                                  value={values.user.first_name}
                                  onChange={handleChange}
                                  required/>
                  </Form.Group>

                </Col>
              </Row>

              <Row>
                <Col>

                  <Form.Group>
                    <Form.Label>Last name in Greek</Form.Label>
                    <Form.Control type="text"
                                  name="user.last_name_greek"
                                  value={values.user.last_name_greek}
                                  onChange={handleChange}
                    />
                  </Form.Group>

                </Col>
                <Col>

                  <Form.Group>
                    <Form.Label>First name in Greek</Form.Label>
                    <Form.Control type="text"
                                  name="user.first_name_greek"
                                  value={values.user.first_name_greek}
                                  onChange={handleChange}/>
                  </Form.Group>

                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Email(s) (comma-seperated)</Form.Label>
                    <Form.Control type="text"
                                  name="user.email"
                                  value={values.user.email}
                                  onChange={handleChange}
                                  required/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Mobile number</Form.Label>
                    <Form.Control type="text"
                                  name="user.phone"
                                  value={values.user.phone}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Text className="text-danger">
                      Warning! Only mobile numbers can be contacted by SMS. Please use country code for numbers that do
                      not start with +30.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Address line 1</Form.Label>
                    <Form.Control type="text"
                                  name="user.address.line_1"
                                  value={values.user.address.line_1}
                                  onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text"
                                  name="user.address.city"
                                  value={values.user.address.city}
                                  onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Postal code (TK)</Form.Label>
                    <Form.Control type="text"
                                  name="user.address.zip"
                                  value={values.user.address.zip}
                                  onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text"
                                  name="user.address.country"
                                  value={values.user.address.country}
                                  onChange={handleChange}
                    />
                    <Form.Text className="text-muted">
                      Leave blank if same country as our entity.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Tax identification number</Form.Label>
                    <Form.Control type="text"
                                  name="user.tax_id"
                                  value={values.user.tax_id}
                                  onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Document type</Form.Label>
                    <Form.Control as="select"
                                  name="user.id_form"
                                  value={values.user.id_form}
                                  onChange={handleChange}
                    >
                      <option value="">None</option>
                      <option value="id_card">ID card</option>
                      <option value="passport">Passport</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Document number</Form.Label>
                    <Form.Control type="text"
                                  name="user.id_number"
                                  value={values.user.id_number}
                                  onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                {values.locale.split('-')[1] === 'gr' && values.billing_type === 'selfemployed' ?
                  <Col>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        label="Apply 0% VAT"
                        name="company.extra_vat"
                        checked={values.company?.extra_vat && values.company.extra_vat == -0.24}
                        onChange={e => {
                          const value = e.target.checked ? -0.24 : 0;
                          setFieldValue('company.extra_vat', value);
                        }}
                      />
                      <Form.Check
                        className={'mt-4'}
                        type="checkbox"
                        label="Apply 17% VAT"
                        name="company.extra_vat"
                        checked={values.company?.extra_vat && values.company.extra_vat == -0.07}
                        onChange={e => {
                          const value = e.target.checked ? -0.07 : 0;
                          setFieldValue('company.extra_vat', value);
                        }}
                      />
                    </Form.Group>
                    
                  </Col>
                  : null }
              </Row>
            </Card.Body>
          </Card>
          {values.billing_type === "company" ?
            <Card>
              <Card.Header as="h4">Company</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Company name</Form.Label>
                      <Form.Control type="text"
                                    name="company.name"
                                    value={values.company.name}
                                    onChange={handleChange}
                                    required
                      />
                      <Form.Text className="text-muted">
                        Please add legal company form (for example IKE, AE or Ltd). Trade names may only be added in
                        parentheses.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Director's last name</Form.Label>
                      <Form.Control type="text"
                                    name="company.director.last_name"
                                    value={values.company.director.last_name}
                                    onChange={handleChange}
                      />
                      <Form.Text className="text-muted">
                        Only necessary if it deviates from the user.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Director's first name</Form.Label>
                      <Form.Control type="text"
                                    name="company.director.first_name"
                                    value={values.company.director.first_name}
                                    onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Address line 1</Form.Label>
                      <Form.Control type="text"
                                    name="company.address.line_1"
                                    value={values.company.address.line_1}
                                    onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control type="text"
                                    name="company.address.city"
                                    value={values.company.address.city}
                                    onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Postal code (TK)</Form.Label>
                      <Form.Control type="text"
                                    name="company.address.zip"
                                    value={values.company.address.zip}
                                    onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control type="text"
                                    name="company.address.country"
                                    value={values.company.address.country}
                                    onChange={handleChange}
                      />
                      <Form.Text className="text-muted">
                        Leave blank if same country as our entity.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Tax identification number</Form.Label>
                      <Form.Control type="text"
                                    name="company.tax_id"
                                    value={values.company.tax_id}
                                    onChange={handleChange}
                      />
                      {values.company.reverse_charge_applies === true ?
                        <Form.Text className="text-muted">
                          Please enter a valid EU VAT number with country prefix. (The country prefix is the two letters
                          in front of the VAT number that specify the country in which the company is located in.)
                        </Form.Text>
                        : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>EU Single Market Reverse Charge</Form.Label>
                      <Form.Check
                        type="checkbox"
                        label="Apply 0% VAT"
                        name="company.reverse_charge_applies"
                        checked={values.company.reverse_charge_applies}
                        onChange={handleChange}
                        value={values.company.reverse_charge_applies}
                      />
                      {values.locale.split('-')[1] === 'gr' ?
                        <Form.Check
                          type="checkbox"
                          label="Apply 17% VAT"
                          name="company.extra_vat"
                          checked={values.company?.extra_vat && values.company.extra_vat < 0}
                          onChange={e => {
                            const value = e.target.checked ? -0.07 : 0;
                            setFieldValue('company.extra_vat', value);
                          }}
                        /> 
                      : null }
                      <Form.Text className="text-muted">
                        Please check correctness of VAT number with <a href="http://ec.europa.eu/taxation_customs/vies/"
                                                                       target="_blank" rel="noopener noreferrer">the
                        authority</a>.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            : null}

          <FieldArray
            name="contracts"
            render={arrayHelpers => (
              <>
                <Card body>
                  <h4>
                    Contracts
                    <Button
                      variant="success"
                      style={{ marginLeft: "10px" }}
                      size="sm"
                      onClick={() => arrayHelpers.push({
                        "car_model": "",
                        "car_license_plate": "",
                        "car_delivered_date": moment().format('YYYY-MM-DD'),
                        "installment": 0,
                        "included_kms": 1500,
                        "included_kms_price": 0,
                        "addedInThisSession": true
                      })
                      }>
                      Add contract
                    </Button>
                  </h4>
                </Card>
                <Accordion>
                  {values.contracts && values.contracts.map((contract, idx) => (
                    <Card key={idx}>
                      <Card.Header>
                        <Row>
                          <Col>

                            <Form.Group>
                              <Form.Label>Car model</Form.Label>
                              <Form.Control type="text"
                                            name={`contracts[${idx}].car_model`}
                                            value={values.contracts[idx].car_model}
                                            onChange={handleChange}
                                            required
                              />
                            </Form.Group>

                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>License plate</Form.Label>
                              <Form.Control type="text"
                                            name={`contracts[${idx}].car_license_plate`}
                                            value={values.contracts[idx].car_license_plate}
                                            onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Car delivered on</Form.Label>
                              <Form.Control type="date"
                                            name={`contracts[${idx}].car_delivered_date`}
                                            value={values.contracts[idx].car_delivered_date}
                                            onChange={handleChange}
                                            required
                                            disabled={contract.locked === true}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={1} style={{ textAlign: "center" }}>
                            <Form.Group>
                              <Form.Label>Electric</Form.Label>
                              <Form.Control type="checkbox"
                                            name={`contracts[${idx}].is_electric`}
                                            value={values.contracts[idx].is_electric || false}
                                            checked={values.contracts[idx].is_electric || false}
                                            onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={1}>
                            <div style={{ position: "absolute", bottom: "16px", right: "12px" }}>
                              <Accordion.Toggle as={Button} eventKey={`${idx}`}>
                                <FontAwesomeIcon icon={faChevronRight}/>
                              </Accordion.Toggle>
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>

                      <Accordion.Collapse eventKey={`${idx}`}>
                        <Card.Body>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Monthly installment</Form.Label>
                                <Form.Control type="number" step="0.01" min="0"
                                              name={`contracts[${idx}].installment`}
                                              value={values.contracts[idx].installment}
                                              onChange={handleChange}
                                              required
                                />
                                <Form.Text className="text-muted">
                                  EUR. Does not include VAT.
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>FlexDeposit</Form.Label>
                                <Form.Control type="number" step="0.01" min="0"
                                              name={`contracts[${idx}].flexdeposit`}
                                              value={values.contracts[idx].flexdeposit}
                                              onChange={handleChange}
                                              placeholder={values.contracts[idx].installment * 2}
                                />
                                <Form.Text className="text-muted">
                                  EUR. Does not include VAT. Leave blank for default value.
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>Km package price</Form.Label>
                                <Form.Control type="number" step="0.01" min="0"
                                              name={`contracts[${idx}].included_kms_price`}
                                              value={values.contracts[idx].included_kms_price}
                                              onChange={handleChange}
                                              required
                                />
                                <Form.Text className="text-muted">
                                  EUR. Does not include VAT. This is part of the monthly installment.
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>Included kms</Form.Label>
                                <Form.Control type="number" step="1" min="1000"
                                              name={`contracts[${idx}].included_kms`}
                                              value={values.contracts[idx].included_kms}
                                              onChange={handleChange}
                                              required
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label>Vehicle Type</Form.Label>
                                <Form.Control as="select"
                                              name={`contracts[${idx}].vehicle_type`}
                                              value={values.contracts[idx].vehicle_type}
                                              onChange={handleChange}
                                >
                                  {config.vehicleTypes.map((el, idx) => {
                                    return <option key={idx + 1} value={el.key}>{el.text}</option>;
                                  })}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>


                          <Form.Group>
                            <Form.Label>Contract details free text</Form.Label>
                            <Form.Control as="textarea" rows="2"
                                          name={`contracts[${idx}].free_text_details`}
                                          value={values.contracts[idx].free_text_details}
                                          onChange={handleChange}
                            />
                          </Form.Group>

                          {idx >= 0 && contract.addedInThisSession ?
                            <Button variant="danger" onClick={() => arrayHelpers.remove(idx)}>
                              Delete contract
                            </Button>
                            : null}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </>
            )}
          />

          <hr/>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Submit
          </Button>

        </Form>
      )}
    </Formik>
  );
}