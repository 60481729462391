import React, { useState, useEffect }   from 'react';
import { Table, Button, ButtonToolbar } from 'react-bootstrap';
import moment                           from 'moment-timezone';
import { toast }                        from 'react-toastify';
import { useKeycloak }                  from "@react-keycloak/web";

import { fetchData } from '../fetchData';

export function ExternalAccounts(props) {
  const [billingAccounts, setBillingAccounts] = useState([]);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    fetchData({
      url: '/api/externals',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      onSuccess: data => {
        data.data.sort((a, b) => {
          if (a.add_at < b.add_at) {
            return 1;
          } else if (a.add_at > b.add_at) {
            return -1;
          } else {
            return 0;
          }
        });
        data.data.forEach(el => {
          el.billing_type = el.billing_type === 'private' ? 'individual' : el.billing_type; // API inconsistency 
        });
        setBillingAccounts(data.data);
      }
    });
  }, [keycloak]);

  const handleAccountDone = id => {
    fetchData({
      url: `/api/externals/${id}`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      onSuccess: () => {
        setBillingAccounts(billingAccounts.filter(el => el.id !== id));
        toast.success("Hidden. Data remains saved in other service's database.");
      }
    });
  }

  return (
    <Table striped bordered hover size="sm">
      <thead>
      <tr>
        <th>Billing type</th>
        <th>Name</th>
        <th>Email</th>
        <th>Submitted on (newest on top)</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      {billingAccounts.length === 0 ?
        <tr>
          <td colSpan="5">Nothing here.</td>
        </tr>
        : billingAccounts.map((account, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {account.billing_type}
                </td>
                <td>
                  {`${account.user.last_name.toUpperCase()}, ${account.user.first_name.toUpperCase()}`}
                </td>
                <td>
                  {account.user.email}
                </td>
                <td>
                  {moment(account.add_at).tz('Europe/Athens').format('DD.MM.YY')}
                </td>
                <td>
                  <ButtonToolbar>
                    <Button variant="success" onClick={() => props.addAccountwithExternalData(account)}>
                      Add
                    </Button>
                    <Button variant="warning" onClick={() => handleAccountDone(account.id)}>
                      Hide
                    </Button>
                  </ButtonToolbar>
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </Table>
  );
}