export function merge(old, current) {
    return {
        // prefer new (aka current)
        ...old,
        billing_type: current.billing_type,
        locale: current.locale,
        user: {
          ...old.user,
          ...current.user
        },
        company: {
          ...old.company,
          ...current.company
          // director?
        },
        // prefer old
        client_id: old.client_id,
        contracts: [...old.contracts]
    }
}