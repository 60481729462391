import React, { useState } from 'react';
import { Button, Table, Form, Col, Row, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faTrash, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

import { fetchDataWithAccount } from '../fetchData';
import { useAccount }           from './context/useAccount';
import { useKeycloak }          from "@react-keycloak/web";

export default function StatusPage(props) {
  const [status, setStatus] = useState(''); 
  const [loading, setLoading] = useState(false); 
  const { setAccount } = useAccount();
  const { keycloak } = useKeycloak();

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if(loading === true) return;
    setLoading(true);

    setStatus('');

    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/status`, 
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      body: {
        description: status,
        status_done: false
      }
    }, setAccount).finally(() => setLoading(false));
  }  

  return (
    <>
      <Table responsive>
        <thead>
        <tr>
          <th>Status</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
          {props.account.status_history && props.account.status_history.length !== 0 ? 
            <StatusHistory 
              account={props.account} 
              status_history={props.account.status_history} 
            />
          : <tr><td colSpan="3">Nothing here.</td></tr> }
        </tbody>
      </Table>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Col xs={10}>
            <Form.Control 
              type="text" 
              value={status}
              onChange={event => setStatus(event.target.value)} 
            />
          </Col>
          <Col xs={2}>
            <Button
              disabled={loading || status.trim() === ''} 
              type="submit"
            >
              Add status
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </>        
  );
}

function StatusHistory(props) {
  const { setAccount } = useAccount();
  const { keycloak } = useKeycloak();

  return props.status_history.map((el, idx) => (
    <tr key={idx}>
      <td>
        {el.status_done === true ?
          <FontAwesomeIcon icon={faCheckCircle} color='green' />
          : <FontAwesomeIcon icon={faTimesCircle} color='red' />}
      </td>
      <td>
        <p style={{ marginBottom: '0em' }}>
          {el.description}
        </p>
        <p style={{ fontSize: 'x-small', marginBottom: '0em' }}>
          {moment(el.created_on).tz('Europe/Athens').format('lll')}
        </p>
      </td>
      <td>
        <ButtonToolbar>
          <Button
            variant="danger"
            onClick={() => fetchDataWithAccount({
              url: `/api/account/${props.account._id}/status/${el._id}`,
              headers: {
                'Authorization': `Bearer ${keycloak.token}`,
              },
              method: 'DELETE'
            }, setAccount)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            variant={el.status_done === false ? "success" : "secondary"}
            onClick={() => fetchDataWithAccount({
              url: `/api/account/${props.account._id}/status/${el._id}`,
              headers: {
                'Authorization': `Bearer ${keycloak.token}`,
              },
              method: 'PUT'
            }, setAccount)}
          >
            <FontAwesomeIcon icon={faToggleOn} />
          </Button>
        </ButtonToolbar>
      </td>
    </tr>
  ));
}