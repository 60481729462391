import React, { useState } from 'react';
import { AccountContext } from './AccountContext';

export default ({ children }) => {
  const [account, setAccount] = useState(null);
  const [invoices, setInvoices] = useState(null);

  return (
    <AccountContext.Provider value={{
      account,
      setAccount: data => setAccount(data),
      invoices,
      setInvoices:  data => setInvoices(data)
    }}>
      {children}
    </AccountContext.Provider>
  );
}