const CurrencyFormatter = new Intl.NumberFormat('de-DE', { 
  style: 'currency', 
  currency: 'EUR' 
});

export const formatCurrency = (number) => {
  if(number.toFixed(2) === '-0.00' || number.toFixed(2) === '0.00') {
    number = 0.0000;
  }
  return CurrencyFormatter.format(number);
}