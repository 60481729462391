import React, { useState }     from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast }               from 'react-toastify';
import moment                  from 'moment-timezone';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import { faTrash }             from '@fortawesome/free-solid-svg-icons';
import { useEffect }           from 'react';
import { useKeycloak }         from "@react-keycloak/web";

import { fetchDataWithAccount } from '../fetchData';
import { useAccount }           from './context/useAccount';


export function SubmitPaymentModal(props) {
  const [loading, setLoading] = useState(false);
  const { setAccount } = useAccount();
  const { keycloak } = useKeycloak();

  const handleDeletePayment = () => {
    if (loading === true) return;
    setLoading(true);

    fetchDataWithAccount({
      url: `/api/account/${props.account._id}/payment/${props.payment._id}`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      method: 'DELETE',
      onSuccess: () => {
        toast.success(`Payment has been deleted.`);
        props.onHide();
      }
    }, setAccount).finally(() => {
      setLoading(false);
    });
  }

  return (
    <Modal
      show={props.show}
      size="lg"
      animation={false}
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InvoicePaidForm
          {...props}
          setLoading={val => setLoading(val)}
          loading={loading}
        />
      </Modal.Body>
      {props.method === 'edit' ?
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleDeletePayment}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faTrash}/>
          </Button>
        </Modal.Footer>
        : null}
    </Modal>
  );
}

const formatDate = (date) => moment(date).format("YYYY-MM-DD");

function InvoicePaidForm(props) {
  const { account, setAccount } = useAccount();

  const [state, setState] = useState({
    comment: "",
    amount_paid: 0,
    received_at_bank_account: account.locale.split('-')[1] === 'cy' ? "astro" : "alpha", // TODO code duplication
    bank_date: formatDate(new Date())
  });
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (props.payment) {
      setState({
        ...props.payment,
        amount_paid: props.payment.amount_paid.toString().replace('.', ','),
        bank_date: formatDate(props.payment.bank_date),
        old_amount_paid: props.payment.amount_paid
      });
    }
  }, [props.payment]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.setLoading(true);
    const amount_paid = Number(state.amount_paid.replace(',', '.'));

    let url = `/api/account/${props.account._id}/payment`;
    let method = 'POST';
    let message = `A payment of €${amount_paid.toFixed(2)} was added to the account.`;

    if (props.method === 'new') {

    } else if (props.method === 'edit') {
      url += `/${props.payment._id}`;
      method = 'PUT';
      message = 'Payment edited.';
    } else {
      throw new Error('Incorrect method provided.');
    }

    fetchDataWithAccount({
      url,
      method,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      body: {
        comment: state.comment,
        amount_paid: amount_paid,
        received_at_bank_account: state.received_at_bank_account,
        bank_date: new Date(state.bank_date)
      },
      onSuccess: () => {
        toast.success(message);
        props.onHide();
        console.log(props.loading)
      }
    }, setAccount).finally(() => {
      props.setLoading(false);
    });
  }

  const handleChange = event => {
    const target = event.target;

    setState({
      ...state,
      [target.name]: target.value
    });
  }


  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Bank</Form.Label>
        <Form.Control as="select"
                      onChange={handleChange}
                      defaultValue={account.locale.split('-')[1] === 'cy' ? "astro" : "alpha" /* TODO code duplication */}
                      name="received_at_bank_account"
        >
          {/* TODO sync bank identifiers (value key) with public config */}
          {account.locale.split('-')[1] === 'cy' ?
            <>
              <option value="astro">Astrobank (Rentals Ltd)</option>
              <option value="viva_cy">Viva Wallet</option>
              <option value="hellenic">Hellenic Bank</option>
            </>
            :
            <>
              <option value="alpha">Alpha Bank</option>
              <option value="nationalbank">National Bank of Greece</option>
              <option value="attica">Attica Bank</option>
              <option value="viva">Viva Wallet</option>
              <option value="eurobank">Eurobank</option>
              <option value="piraeus">Piraeus Bank</option>
              <option value="bcm">Bank of Central Macedonia</option>
              <option value="other">Other</option>
            </>
          }

        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Comment/Reason</Form.Label>
        <Form.Control as="textarea"
                      name="comment"
                      value={state.comment}
                      onChange={handleChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Date at bank (not valeur date)</Form.Label>
        <Form.Control
          type="date"
          name="bank_date"
          value={state.bank_date}
          onChange={handleChange}
          max={moment().tz('Europe/Athens').format('YYYY-MM-DD')}
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Amount paid</Form.Label>
        <Form.Control
          type="text"
          name="amount_paid"
          value={state.amount_paid}
          onChange={handleChange}
          pattern="(-)?([1-9][0-9]*(,[0-9]{1,2})?)|0,[0-9]{1,2}"
          required
          autoComplete="off"
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={props.loading}
      >
        Submit
      </Button>
    </Form>
  );
}