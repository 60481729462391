import React, { useEffect, useState } from 'react';
import { Table, Button, OverlayTrigger, Popover, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { SubmitPaymentModal }        from './SubmitPaymentModal';
import { DeleteInvoice }             from './DeleteInvoice';
import { formatCurrency }            from '../CurrencyFormatter';
import { SubmitPaymentModalWrapper } from './SubmitPaymentModalWrapper';

import './paymenthistory.css';
import { INVOICE_TYPES }             from "../externals/invoiceTypes";


export function PaymentHistory(props) {

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [events, setEvents] = useState([]);
  const [outstandingBalanceViewOn, setOutstandingBalanceViewOn] = useState(false);
  const [firstDeadlineIdx, setFirstDeadlineIdx] = useState(null);

  useEffect(() => {

    const payments = props.account.payments.map(payment => {
      return ({
        date: payment.bank_date,
        event: -payment.amount_paid > 0 ? "Bank transfer (outgoing)" : "Bank transfer",
        effect_on_balance: -payment.amount_paid,
        payment: payment
      });
    });

    const invoices = props.invoices.map(invoice => {
      let invoice_type = invoice.entity_type === "individual" ? "ALS" : "TPI";
      if (invoice.country_code === "cy") {
        invoice_type = "TIM";
      }
      if (invoice.is_credit) {
        invoice_type = "CREDIT_" + invoice_type;
      }
      invoice_type += "_";
      const text = `${invoice_type}${invoice.invoice_number}`;
      return ({
        date: invoice.generated_at,
        event: text,
        effect_on_balance: invoice.gross_value,
        invoice: invoice
      });
    });

    let arr = [...payments, ...invoices];

    arr.forEach(a => a.date = moment(a.date));
    arr.sort((a, b) => b.date.unix() - a.date.unix());

    setEvents(arr);
  }, [props.account, props.invoices]);

  const switchToOutstandingBalances = () => {
    if(outstandingBalanceViewOn === true) {
      setOutstandingBalanceViewOn(false);
      setEvents([...events].sort((a, b) => b.date.unix() - a.date.unix()));
      return;
    }
    else {
      setOutstandingBalanceViewOn(true);
    }

    const evts = [...events];

    const today = new Date();
    setEvents(evts.sort((a, b) => {
      const a_on_top = a.invoice && new Date(a.invoice.deadline_date) > today;
      const b_on_top = b.invoice && new Date(b.invoice.deadline_date) > today;

      if(a_on_top || b_on_top) {
        if(a_on_top && b_on_top) {
          if(new Date(b.invoice.deadline_date) < new Date(a.invoice.deadline_date)) return -1;
          if(new Date(b.invoice.deadline_date) > new Date(a.invoice.deadline_date)) return 1;
          return 0;
        }
        if(a_on_top) return -1;
        return 1;
      }
      else {
        return b.date.unix() - a.date.unix();
      }
    }));

    for(let i = evts.length - 1; i >= 0; --i) {
      if(evts[i].invoice && new Date(evts[i].invoice.deadline_date) > today) {
        setFirstDeadlineIdx(i+1);
        return;
      }
    }
    setFirstDeadlineIdx(0);
  }

  const handleOpenPaymentEditModal = (payment) => {
    setSelectedPayment(payment);
    setPaymentModalOpen(!paymentModalOpen);
  }

  let balance = props.account.balance;
  const txs_and_invoices = events.map(el => {
    const obj = {
      ...el,
      balance: balance
    };
    if (el.invoice && el.invoice.is_cancelled && el.invoice.is_cancelled === true) {
      // leave balance unchange
    }
    else {
      balance -= el.effect_on_balance;
    }
    return obj;
  });

  return (
    <>
      <SubmitPaymentModalWrapper account={props.account} />
      <Button
        variant="secondary"
        onClick={switchToOutstandingBalances}
        style={{ marginTop: '10px', marginBottom: '5px' }}
      >
        { outstandingBalanceViewOn === true ? 'Chronological view' 
        : 'Outstanding balances view' }
      </Button>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Issue date</th>
            <th>Event</th>
            <th>Details</th>
            <th>Rental period<sup>1</sup></th>
            <th>Deadline date</th>
            <th>License plate</th>
            <th>Amount</th>
            <th>{outstandingBalanceViewOn === true ? 'Outstanding balance' : 'Open balance'}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {txs_and_invoices.map((event, k) => (
            <tr key={k} className={
              outstandingBalanceViewOn === true && k === firstDeadlineIdx ? 'table-warning' : null
            }>
              <td>
                {event.date.format('DD.MM.YYYY')}
              </td>
              <td>
                {event.invoice ?
                  <OverlayTrigger
                    transition={false}
                    trigger="click"
                    placement="right"
                    overlay={
                      <Popover>
                        <Popover.Title as="h3">Invoice details (in JSON)</Popover.Title>
                        <Popover.Content>
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            {JSON.stringify(
                              event.invoice.invoice_items, 
                              (key, value) => (key === "_id" ? undefined : value), 
                              "\t"
                            )}
                          </div>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <div>
                      {event.event}
                      {event.invoice.is_cancelled ?
                        <>
                          &nbsp;
                          <Badge variant="danger">
                            {event.invoice.is_credit ? "cancelled with credit invoice" : `cancelled by No. ${event.invoice.cancellation_number}`}
                          </Badge>
                        </>
                        : null}
                    </div>
                  </OverlayTrigger>
                  : event.event}
              </td>
              <td>
                {event.invoice && event.invoice.meta ? event.invoice.meta.description_short 
                : (event.payment ? event.payment.received_at_bank_account : null)}
                {event.invoice && event.invoice.meta && event.invoice?.invoice_type?.length > 0 ? ` (${INVOICE_TYPES.find(t => t.key === event.invoice?.invoice_type).value })` : ''}
              </td>
              <td>
                {event.invoice && event.invoice.meta.start_date ? `${event.invoice.meta.start_date} - ${event.invoice.meta.end_date}` 
                : event.invoice ? event.invoice.fulfillment_date 
                : null}
              </td>
              <td>
                {event.invoice ? moment(event.invoice.deadline_date).format('DD.MM.YYYY') : null}
              </td>
              <td>
                {event.invoice && event.invoice.meta ? event.invoice.meta.car_license_plate : null}
              </td>
              <td 
                style={{ textAlign: "right", paddingRight: "2rem" }}
                className={
                  outstandingBalanceViewOn === true && k <= firstDeadlineIdx ? 'table-success' : null
                }
              >
                <div 
                  style={event.invoice && event.invoice.is_cancelled ? { textDecoration: "line-through" } : null}
                >
                  {formatCurrency(event.effect_on_balance)}
                </div>
              </td>
              <td style={{ textAlign: "right", paddingRight: "2rem" }}>
                {formatCurrency(event.balance)}
              </td>
              <td>
                {event.invoice ? null :
                  <Button
                    variant="secondary"
                    onClick={() => handleOpenPaymentEditModal(event.payment)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                }
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan="6"><sup>1</sup> Fulfillment date if only one date is displayed.</td>
            <td style={{ textAlign: "right", paddingRight: "2rem" }}>
              <b>Starting balance<sup>2</sup>:</b>
            </td>
            <td style={{ textAlign: "right", paddingRight: "2rem" }}>
              <b>{formatCurrency(balance)}</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="9">
              <sup>2</sup> Transactions and invoices before October 2019 are not included. In that case the starting balance might not be zero.
            </td>
          </tr>
        </tbody>
      </Table>
      <SubmitPaymentModal
        show={paymentModalOpen}
        method='edit'
        payment={selectedPayment}
        onHide={handleOpenPaymentEditModal}
        account={props.account}
      />
    </>
  );
}