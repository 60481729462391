import React from 'react';
import { Modal, Tabs, Tab, Badge, Spinner } from 'react-bootstrap';

import FormFrame from '../form_frame/FormFrame';
import { PaymentHistory } from './PaymentHistory';
import { Contracts } from './Contracts';
import Settings from './Settings';
import StatusPage from './StatusPage';
import { useAccount } from './context/useAccount';

import '../accountstable.css';

export default function AccountDetailsModal(props) {
  const { account, invoices } = useAccount();

  return (
    <Modal size="xl" centered show={props.show} onHide={props.handleModalClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>
        { props.loading ? "Loading..." :
          <span>
            Account: {`${account.user.last_name}, ${account.user.first_name}, ${account.client_id}`} 
            {account.locale.split('-')[1] === 'cy' ? ' 🇨🇾' : null}
            <Badge variant="info">{account.migration_version}</Badge>
          </span>
        }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.loading ? 
          <div style={{minHeight: '150px'}}>
            <div style={{ position: 'fixed', top: '50%', left: '50%' }} >
              <Spinner animation="border" /> 
            </div> 
          </div>
        :
          <Tabs defaultActiveKey="history" transition={false}>
            <Tab eventKey="profile" title="Profile">
              <FormFrame 
                account={account}
                hasInvoices={invoices?.length > 0}
                method="update"
              />
            </Tab>
            <Tab eventKey="contracts" title="Contracts">
              <Contracts account={account} />
            </Tab>
            <Tab eventKey="history" title="History">
              <PaymentHistory account={account} invoices={invoices} />
            </Tab>
            <Tab eventKey="status" title="Status">
              <StatusPage account={account} />
            </Tab>
            <Tab eventKey="settings" title="Settings">
              <Settings account={account} />
            </Tab>
          </Tabs>
        }
      </Modal.Body>
    </Modal>
  );
}