import { toast } from 'react-toastify';

import { fetchWithTimeout } from './fetchWithTimeout'

export async function fetchData({ url, method, body, onSuccess, onError, onWarning, headers={} }) {
  try {
    const t0 = performance.now();

    const res = await fetchWithTimeout(url, {
      method: method === undefined ? 'GET' : method,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(body)
    });

    const t1 = performance.now();
    console.log(`Request took ${(t1-t0).toFixed(0)}ms.`);
    
    const data = await res.json();
    
    if(res.ok) {
      if(data.status === 'warning') {
        if(onWarning) onWarning(data.message, data);
      }
      else if(data.status === 'success') {
        if(onSuccess) onSuccess(data);
      }
      else if(data.status === 'error') {
        if(onError) onError(data.message);
      }
      else {
        throw new Error('Invalid status.')
      }
    }
    else {
      throw new Error(data.message);
    }
  }
  catch(error) {
    throw new Error(error);
  }
}

export function fetchDataWithAccount(obj, setAccount) {
  return fetchData({
    ...obj,
    onSuccess: data => {
      setAccount(data.account);

      if(obj.onSuccess) {
        obj.onSuccess(data);
      }
      else {
        if(data.message) toast.success(data.message);
      }
    },
    onError: error => { 
      if(obj.onError) {
        obj.onError(error)
      }
      else {
        toast.error(error, { autoClose: false });
      }
    },
    onWarning: (warning, data) => {
      setAccount(data.account);

      if(obj.onWarning) {
        obj.onWarning(warning)
      }
      else {
        toast.warning(
          `Warning: ${warning} The operation was executed successfully, however.`, 
          { autoClose: false }
        );
      }
    }
  });
}