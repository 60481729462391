import React, { useState, useEffect }                                  from 'react';
import moment                                                          from 'moment-timezone';
import { Button, Form }                                                from 'react-bootstrap';
import { toast }                                                       from 'react-toastify';
import { calculateRentPeriodDatesFromContract, fractionPerTimePeriod } from '@flexcar/dateutils';
import { useKeycloak }                                                 from "@react-keycloak/web";

import InvoiceItemsForm                    from './InvoiceItemsForm';
import { useAccount }                      from './account/context/useAccount';
import { fetchDataWithAccount, fetchData } from './fetchData';
import { INVOICE_TYPES }                   from './externals/invoiceTypes'

import './accountstable.css';

export default function InvoiceForm(props) {
  const [invoice_items, set_invoice_items] = useState([]);
  const [associated_contract_id, set_associated_contract_id] = useState('');
  const [loading, setLoading] = useState(false);
  const [fulfillment_date, set_fulfillment_date] = useState(moment().format('YYYY-MM-DD'));
  const [deadline_date, set_deadline_date] = useState(moment().format('YYYY-MM-DD'));
  const [invoice_until_date, set_invoice_until_date] = useState(moment().format('YYYY-MM-DD'));
  const [invoice_until_date_helper, set_invoice_until_date_helper] = useState('');
  const [is_fully_custom, set_is_fully_custom] = useState(false);
  const [invoice_type, set_invoice_type] = useState('');
  const [custom_date] = useState('');

  const { account, setAccount, setInvoices } = useAccount();
  const { keycloak } = useKeycloak();

  const handleInvoiceItems = items => {
    items.forEach((item) => {
      item.net_amount = Number(item.net_amount);
    })

    set_invoice_items(items);
  }
  
  useEffect(() => {
    if (associated_contract_id === '') return;
    const contract = account.contracts.filter(c => c._id === associated_contract_id)[0];
    const { rent_period_start } = calculateRentPeriodDatesFromContract(contract);
    const t0 = rent_period_start;
    const t1 = invoice_until_date;
    console.log(t0, t1)
    const fraction = fractionPerTimePeriod(
      rent_period_start,
      new Date(invoice_until_date)
    );
    const if_credit = fraction < 0 ?
      <span
        style={{ textDecoration: 'underline' }}><br/>Rent period will be moved back. The amount will be credited.</span>
      : null;
    const s = <>
      Invoice period [incl.; excl.): [{moment(t0).format('DD.MM.YYYY')}; {moment(t1).format('DD.MM.YYYY')})<br/>
      Amount excl. VAT: {(fraction * contract.installment).toFixed(2)} EUR<br/>
      Percentage of regular installment: {(fraction * 100).toFixed(2)} %<br/>
      {if_credit}
    </>
    set_invoice_until_date_helper(s);
  }, [associated_contract_id, invoice_until_date, account.contracts]);

  useEffect(() => {
    if (associated_contract_id === '') {
      set_is_fully_custom(true);
      return;
    }
    const contract = account.contracts.filter(c => c._id === associated_contract_id)[0];
    const { rent_period_end } = calculateRentPeriodDatesFromContract(contract);
    set_invoice_until_date(moment(rent_period_end).format('YYYY-MM-DD'));
  }, [associated_contract_id, account.contracts]);

  const handleSubmit = event => {
    event.preventDefault();
    if (loading === true) return;

    setLoading(true);
    const items = [...invoice_items];

    items.forEach(item => {
      item.description = item.description.split('\n').join('<br />');
    });

    fetchDataWithAccount({
      url: `/api/account/${account._id}/custom/invoice/${associated_contract_id}`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      method: 'POST',
      body: is_fully_custom === true ? {
        invoice_items: items,
        deadline_date: new Date(deadline_date),
        fulfillment_date: moment.utc(fulfillment_date).format('DD.MM.YYYY'),
        invoice_type: invoice_type,
        custom_date: custom_date
      } : {
        invoice_until_date: new Date(invoice_until_date)
      },
      onSuccess: () => toast.success('Custom invoice created.')
    }, setAccount).then(() => fetchData({
      url: `/api/invoice/account/${account._id}`,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      onSuccess: data => setInvoices(data.invoices)
    })).finally(() => props.handleClose());
  }

  return (
    <Form onSubmit={handleSubmit}>

      <Form.Group>
        <Form.Label>Is this invoice associated with a contract?</Form.Label>
        <Form.Control as="select"
                      name="associated_contract_id"
                      onChange={event => set_associated_contract_id(event.target.value)}
                      value={associated_contract_id}
        >
          <option value={''}>No (rarely the right choice)</option>
          {account.contracts.map((contract, idx) => (
            <option key={idx} value={contract._id}>
              {contract.car_model}
              {contract.car_license_plate !== '' ? ` (${contract.car_license_plate})` : null}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Check
          type="radio"
          label="Installment period which is unequal to one month"
          checked={is_fully_custom === false}
          value={false}
          onChange={() => set_is_fully_custom(false)}
          disabled={associated_contract_id === ''}
        />
        <Form.Check
          type="radio"
          label="Fully custom (Be cautious! You are on your own. &#x26A0;)"
          checked={is_fully_custom === true}
          value={true}
          onChange={() => set_is_fully_custom(true)}
        />
      </Form.Group>

      {associated_contract_id !== '' && is_fully_custom === false ?
        <>
          <Form.Group>
            <Form.Label>
              End date (which is printed on invoice but not billed)
            </Form.Label>
            <Form.Control type="date"
                          name="invoice_until_date"
                          value={invoice_until_date}
                          onChange={event => set_invoice_until_date(event.target.value)}
                          required
            />
          </Form.Group>
          <div>{invoice_until_date_helper}</div>
          <hr/>
        </>
        :
        <>
          <Form.Group>
            <Form.Label>Deadline date</Form.Label>
            <Form.Control type="date"
                          name="deadline_date"
                          value={deadline_date}
                          onChange={event => set_deadline_date(event.target.value)}
                          required
            />
            <Form.Text className="text-muted">
              When is this invoice due?
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Fulfillment date</Form.Label>
            <Form.Control type="date"
                          name="fulfillment_date"
                          value={fulfillment_date}
                          onChange={event => set_fulfillment_date(event.target.value)}
                          required
            />
            <Form.Text className="text-muted">
              Usually the day when the rental month starts.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control as="select"
                          name="invoice_type"
                          onChange={event => set_invoice_type(event.target.value)}
                          value={invoice_type}
            >
              <option value={''}>Select type</option>
              {INVOICE_TYPES.map((options, idx) => (
                <option key={idx} value={options.key}>
                  {options.value}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/*<Form.Group>
            <Form.Label>Custom Date</Form.Label>
            <Form.Control type="date"
                          name="custom_date"
                          value={custom_date}
                          onChange={event => set_custom_date(event.target.value)}
            />
          </Form.Group>*/}
          <hr/>

          <InvoiceItemsForm handleChange={handleInvoiceItems}/>
        </>
      }

      <Button variant="primary" type="submit" disabled={loading}>
        Submit
      </Button>

    </Form>
  );
}