import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SubmitPaymentModal } from './SubmitPaymentModal';

export function SubmitPaymentModalWrapper(props) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        variant="success"
        onClick={() => setModalShow(true)}
        style={{ marginTop: '10px', marginBottom: '5px' }}
      >
        Add payment
      </Button>

      <SubmitPaymentModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        {...props}
        method="new"
      />
    </>
  );
}

