import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import FormFrame from './FormFrame';
import { ExternalAccounts } from '../externals/ExternalAccounts';
import { merge } from '../externals/merge';

export default function FormFrameWrapper(props) {
    const [open, setOpen] = useState(false);
    const [mergedAcc, setMergedAcc] = useState(props.account);

    return (
        <>
            <FormFrame 
                account={mergedAcc} 
                method={props.method}
                handleExternals={() => setOpen(true)}
            />

            <Modal show={open} onHide={() => setOpen(false)} size='xl' animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add an account from external source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExternalAccounts addAccountwithExternalData={account => {
                        setMergedAcc(merge(props.account, account));
                        setOpen(false);
                    }} /> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}