import React, { useEffect } from 'react';
import { useKeycloak }      from "@react-keycloak/web";
import { toast }            from 'react-toastify';

import { fetchDataWithAccount, fetchData } from '../fetchData';
import { useAccount }                      from './context/useAccount';
import AccountDetailsModal                 from './Account';

export default function AccountWrapper(props) {
  const { account, invoices, setAccount, setInvoices } = useAccount();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (props.account_id !== null) {
      const p1 = fetchDataWithAccount({
        url: `/api/account/${props.account_id}`,
        headers: {
          'Authorization': `Bearer ${keycloak.token}`,
        },
        onSuccess: () => null
      }, setAccount);

      const p2 = fetchData({
        url: `/api/invoice/account/${props.account_id}`,
        headers: {
          'Authorization': `Bearer ${keycloak.token}`,
        },
        onSuccess: data => setInvoices(data.invoices)
      });

      Promise.all([p1, p2])
        .catch(() => toast.error('Account and invoices failed to fetched.'));
    }

    return () => {
      setAccount(null);
      setInvoices(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.account_id, keycloak]);

  return (
    <AccountDetailsModal
      {...props}
      loading={invoices === null || account === null}
    />
  );
}