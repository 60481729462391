import React, { useState, useEffect } from "react";
import { Table, Button, Badge, ButtonToolbar, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import AccountDetailsModal from "./account/AccountWrapper";
import { formatCurrency } from "./CurrencyFormatter";
import { fetchData } from "./fetchData";
import AccountProvider from "./account/context/AccountProvider";
import { compareFunc } from "./compareFunc";

import "./accountstable.css";
import { useKeycloak } from "@react-keycloak/web";

const protocol = process.env.REACT_APP_IS_WSS === "true" ? "wss" : "ws";
const ws = new WebSocket(`${protocol}://${window.location.host}/ws`);
//const ws = new WebSocket(`${protocol}://localhost:49160/ws`);

const selectLanguageDependentFullName = () => "Self-employed"; // TODO

export default function AccountsTable(props) {
  const history = useHistory();

  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    ws.onopen = () => {
      console.log(new Date(), "Connection opened.");
    };

    ws.onclose = () => {
      console.log(new Date(), "Connection closed.");
      history.push("/error");
    };

    ws.onerror = () => {
      history.push("/error");
    };

    return () => {
      console.log(new Date(), "Closing connection.");
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData({
      url: `/api/account?archive=${props.archive}`,
      onSuccess: (data) => setAccounts(data.accounts.sort(compareFunc)),
      onError: (message) => console.error(message),
      onWarning: (message) => console.error(message),
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }).finally(() => setLoading(false));
  }, [props.archive, keycloak]);

  useEffect(() => {
    const onAccountUpdate = (event) => {
      const payload = JSON.parse(event.data);

      if (payload.message_type !== "account_update") return;
      if (props.archive !== payload.account.archive) return;

      if (payload.operation_type === "insert") {
        // TODO send data directly without a backend API call to /reduced/

        setAccounts(accounts.concat(payload.account).sort(compareFunc));
      } else if (payload.operation_type === "update") {
        const accounts_filtered = accounts.filter(
          (el) => el._id !== payload.account._id
        );
        setAccounts(
          accounts_filtered.concat(payload.account).sort(compareFunc)
        );
      }
      // TODO not a case that can happen in the backend
      else if (payload.operation_type === "delete") {
        const accounts_filtered = accounts.filter(
          (el) => el._id !== payload.account._id
        );
        setAccounts(accounts_filtered.sort(compareFunc));
      } else {
        console.log("Account update event was ignored.");
      }
    };
    ws.addEventListener("message", onAccountUpdate, { passive: true });

    return () => ws.removeEventListener("message", onAccountUpdate);
  }, [accounts, props.archive]);

  // Preempt account if it is opened by another client
  useEffect(() => {
    const onAccountOpen = (event) => {
      const payload = JSON.parse(event.data);
      if (payload.message_type !== "opened_account") return;

      console.log(payload.account_id + " " + selectedAccountId);

      if (payload.account_id === selectedAccountId) {
        toast.info(
          `We preempted a viewed account because somebody else (maybe even you in another tab) opened it and is about to make changes to it.`,
          {
            autoClose: false,
          }
        );
        setSelectedAccountId(null);
      }
    };

    ws.addEventListener("message", onAccountOpen, { passive: true });

    return () => ws.removeEventListener("message", onAccountOpen);
  }, [selectedAccountId]);

  const handleModalOpen = (accountId) => {
    setSelectedAccountId(accountId);
    ws.send(
      JSON.stringify({
        message_type: "opened_account",
        account_id: accountId,
      })
    );
  };

  if (loading) {
    return (
      <div style={{ position: "fixed", top: "50%", left: "50%" }}>
        <Spinner animation="border" />
      </div>
    );
  }

  if (accounts.length === 0) {
    return <h2>No accounts found.</h2>;
  }
  return (
    <>
      <Table striped hover size="sm" style={{ marginBottom: "4rem" }}>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Client ID</th>
            <th>
              Name <Badge variant="secondary">Company name</Badge>
            </th>
            <th>Cars</th>
            <th className="d-none d-xl-table-cell">
              Due date <Badge variant="secondary">Open invoices</Badge>
            </th>
            <th className="d-none d-xl-table-cell">Balance</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account, idx) => (
            <RowElement
              key={idx}
              idx={idx + 1}
              account={account}
              handleModalOpen={handleModalOpen}
            />
          ))}
        </tbody>
      </Table>

      <AccountProvider>
        <AccountDetailsModal
          account_id={selectedAccountId}
          show={!!selectedAccountId}
          handleModalClose={() => setSelectedAccountId(null)}
        />
      </AccountProvider>
    </>
  );
}

function RowElement(props) {
  const balance = props.account.balance;

  return (
    <tr>
      <td>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => props.handleModalOpen(props.account._id)}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
      </td>
      <td>{props.idx}</td>
      <td>{props.account.client_id}</td>
      <td>
        {props.account.user.last_name}, {props.account.user.first_name}
        <Badge variant="secondary">
          {props.account.billing_type === "individual"
            ? ""
            : props.account.billing_type === "company"
            ? props.account.company.name
            : selectLanguageDependentFullName(props.account)}
        </Badge>
        {props.account.client_id ? null : (
          <Badge variant="danger">Client ID</Badge>
        )}
      </td>
      <td style={{ maxWidth: "225px" }}>
        <ButtonToolbar>
          {props.account.contracts.map((contract, idx) => (
            <Badge
              variant={
                contract.car_delivered === true ? "secondary" : "warning"
              }
              key={idx}
            >
              {contract.car_license_plate === ""
                ? contract.car_model
                : contract.car_license_plate}
            </Badge>
          ))}
        </ButtonToolbar>
      </td>
      <td className="d-none d-xl-table-cell">
        {props.account.active === true ||
        (props.account.archive && props.account.archive === true) ? (
          <div>
            <div style={{ float: "left", display: "inline-block" }}>
              {props.account.due_date &&
                moment(props.account.due_date).format("DD.MM.YY")}
            </div>
            <DelayCounter {...props} />
            {props.account.open_invoices !== 0 ? (
              <Badge
                style={{ float: "left", display: "inline-block" }}
                variant="secondary"
              >
                {props.account.open_invoices}
              </Badge>
            ) : null}
          </div>
        ) : (
          <Badge variant="secondary">inactive</Badge>
        )}
      </td>
      <td
        style={{ textAlign: "right" }}
        className={`${
          balance > 0 ? "corner corner-danger" : "corner corner-success"
        } d-none d-xl-table-cell`}
      >
        <span style={{ paddingRight: "1em" }}>{formatCurrency(balance)}</span>
      </td>
    </tr>
  );
}

function DelayCounter(props) {
  let bVariant = "warning";
  if (props.account.days_delayed > 3) {
    bVariant = "danger";
  } else if (props.account.days_delayed <= 0) {
    bVariant = "secondary";
  } else {
  }

  return (
    <Badge
      style={{ float: "left", display: "inline-block" }}
      variant={bVariant}
    >
      {props.account.days_delayed}d
    </Badge>
  );
}
