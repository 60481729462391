// https://stackoverflow.com/a/49857905
export function fetchWithTimeout(url, options, timeout = 60000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(
        new Error(`Server request timed out after ${timeout}ms.`)
      ), timeout)
    )
  ]);
}