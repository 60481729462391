import React, { useState } from 'react';

export const ExternalsContext = React.createContext(null);

export function ExternalsContextProvider(props) {

    const [open, setOpen] = useState(false);

    return (
        <ExternalsContext.Provider value={{
            open,
            setOpen
        }}>
            {props.children}
        </ExternalsContext.Provider>
    );
}