import React from 'react';

import { Alert } from 'react-bootstrap';

export function PageError({ code, msg }) {

  return (
    <div style={{padding: '10px'}}>
      <Alert variant='danger'>
        <Alert.Heading>Houston, we have a problem! Error: {code}</Alert.Heading>
        <p>
          {code === 500 ? `An internal server error occurred. Please report this to IT! ${msg ? msg : ''}`
          : code === 404 ? 'This URL path was not found. Please try another one.'
          : null}
        </p>
      </Alert>
    </div>
  );
} 