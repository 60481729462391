import React, { useState }                        from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { toast }                                  from 'react-toastify';
import { useKeycloak }                            from "@react-keycloak/web";

import TopBar                       from './TopBar';
import AccountsTable                from './AccountsTable.jsx';
import RawList                      from './RawList';
import { ExternalsContextProvider } from './externals/context';
import { PageError }                from './PageError';
import { ErrorBoundary }            from './ErrorBoundary';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 3000,
  pauseOnFocusLoss: false
});

export default function App(props) {
  const [showArchive, setShowArchive] = useState(false);
  const { keycloak } = useKeycloak();

  const isAccountant = keycloak.idTokenParsed.groups.includes('accounting');
  const isAdmin = keycloak.idTokenParsed.groups.includes('administrators');

  if (isAccountant || isAdmin) {
    return (
      <ErrorBoundary>
        <ExternalsContextProvider>
          <Router>
            <Switch>
              <Route exact path="/invoices">
                <RawList method="invoice/monthly-invoices"/>
              </Route>
              <Route exact path="/payments">
                <RawList method="report/monthly-payments"/>
              </Route>
              <Route exact path="/">
                <TopBar showArchive={showArchive} handleArchiveButton={() => setShowArchive(!showArchive)}/>
                <AccountsTable archive={showArchive}/>
              </Route>
              <Route exact path="/error" render={() => <PageError code={500}/>}/>
              <Route render={() => <PageError code={404}/>}/>
            </Switch>
          </Router>
        </ExternalsContextProvider>
      </ErrorBoundary>
    );
  } else {
    return (<div className={'text-center h2'}>Not authorized</div>)
  }
};