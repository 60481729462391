import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import InvoiceForm from '../InvoiceForm';

const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '30%',
  transform: 'translate(-50%, -50%) !important'
};

export function NewInvoiceModal(props) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        variant='success'
        style={{ marginTop: '10px', marginBottom: '5px' }}
        onClick={() => setShow(true)}
      >
        New invoice
      </Button>

      <Modal show={show} backdrop={'static'} animation={false} centered style={modalStyles}>
        <Modal.Header>
          <Modal.Title>Generate invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoiceForm handleClose={() => setShow(false)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}