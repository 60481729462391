import React, { useState }     from 'react';
import moment                  from 'moment';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import { faBan, faHistory }    from '@fortawesome/free-solid-svg-icons';
import { toast }               from 'react-toastify';
import { useKeycloak }         from "@react-keycloak/web";

import { useAccount }           from './context/useAccount';
import { fetchDataWithAccount } from '../fetchData';

export default function EndContract(props) {
  const [show, setShow] = useState(false);
  const [contractEndDate, setContractEndDate] = useState(moment().format("YYYY-MM-DD"));
  const { account, setAccount } = useAccount();
  const { keycloak } = useKeycloak();

  const nothing_generated = props.billed_until_date === undefined;

  const handleSubmit = () => {
    const date = new Date(contractEndDate);

    if (!nothing_generated && props.rent_period_end.getTime() < date.getTime()) {
      toast.warning('A portion of the rental period was not billed.', { autoClose: false });
    }

    fetchDataWithAccount({
      url: `/api/account/${account._id}/contract/${props.contract_id}/terminated`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
      body: {
        date: date,
        nothing_generated
      },
      onSuccess: () => nothing_generated ?
        toast.warning('Car delivery reverted.') : toast.success('Contract ended.')
    }, setAccount);

    setShow(false);
  }

  return (
    <>
      <Modal show={show} animation={false}>
        <Modal.Header>
          <Modal.Title>Car return date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={evt => {
            evt.preventDefault();
            handleSubmit();
          }}>
            <Form.Group>
              <Form.Control
                type="date"
                name="contractEndDate"
                value={contractEndDate}
                onChange={evt => setContractEndDate(evt.target.value)}
                required
                disabled={nothing_generated}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Button
        variant={nothing_generated ? "warning" : "danger"}
        onClick={nothing_generated ? handleSubmit : () => setShow(true)}
      >
        <FontAwesomeIcon icon={nothing_generated ? faHistory : faBan}/>
      </Button>
    </>
  );
}