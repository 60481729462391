import React, { useState, useEffect } from 'react';
import { Spinner }                    from 'react-bootstrap';
import moment                         from 'moment-timezone';
import { useKeycloak }                from "@react-keycloak/web";

import { fetchData }     from './fetchData';
import { INVOICE_TYPES } from "./externals/invoiceTypes";

function RawList(props) {
  const [state, setState] = useState({
    list: null,
    loading: true,
    error: null
  });

  const { keycloak } = useKeycloak();

  useEffect(() => {
    setState({ list: null, loading: true, error: null });
    fetchData({
      url: `/api/${props.method}?year=${props.year}&month=${props.month}`,
      onSuccess: data => setState({ list: data, loading: false, error: null }),
      onError: error => setState({ list: null, loading: false, error }),
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
    })
  }, [props.method, props.year, props.month, keycloak]);
  if (props.method.includes('monthly-invoices')) {
    var header =
      '"country";"invoice_type";"invoice_number";"invoice_date";"client_id";"branch_afm";"invoice_name";"afm";"email","billing_type","address_line_1";"address_city";"address_tk";"doy";"profession";"net_value";"gross_value";"balance";"description";"car_license_plate";"start_date";"end_date";"cancellation_type";"cancellation_number";"cancellation_date"\n';
    var list = state.list && state.list.invoices.map(inv => {
      let invoice_type = inv.entity_type === 'entrepreneur' ? 'tpi' : 'als';
      if (inv.country_code === 'cy') {
        invoice_type = 'tim';
      }
      if (inv.is_credit === true && inv.is_cancelled === false) {
        invoice_type = 'credit_' + invoice_type
      }
      
      let type = inv.invoice_type?.length > 0 
        ? `(${INVOICE_TYPES.find(t => t.key === inv.invoice_type).value })` 
        : '';
      let branch = inv.associated_account.branch ? inv.associated_account.branch : 'flexcar';
      if (inv.country_code === 'cy' && branch === 'flexcar') {
        branch += '_rentals';
      }
      
      let arr = [
        inv.country_code,
        invoice_type,
        inv.invoice_number,
        inv.invoice_date,
        inv.counterparty.client_id ? inv.counterparty.client_id : '',
        branch,
        inv.counterparty.name,
        `'${inv.counterparty.tax_identification_number}`, // Excel fix to prevent conversion to number in case of leading zero
        inv.associated_account.user.email,
        inv.associated_account.billing_type ?? '-',
        inv.counterparty.address.line_1,
        inv.counterparty.address.city,
        inv.counterparty.address.zip,
        inv.counterparty.tax_office_location,
        inv.counterparty.profession,
        inv.net_value.toFixed(2),
        inv.gross_value.toFixed(2),
        inv.balance_after_issue.toFixed(2),
        `${inv.meta.description_short} ${type}`,
        inv.meta.car_license_plate,
        inv.meta.start_date,
        inv.meta.end_date,
        inv.is_cancelled ? (inv.is_credit ? 'credit invoice' : 'cancellation') : null,
        inv.cancellation_number,
        inv.cancellation_date,
      ];
      return arr.join('";"');
    });
  } else {
    // eslint-disable-next-line
    var header = '"country";"bank_date";"client_id";"afm";"billing_type";"company_name";"last_name";"first_name";"amount_paid";"bank_abbrv";"payment_recorded";"branch"\n';
    // eslint-disable-next-line
    var list = state.list && state.list.payments.map(payment => {   
        let branch = payment.branch ?? null;
      let arr = [
        payment.locale.split('-')[1],
        moment.utc(payment.bank_date).format('DD.MM.YYYY'),
        payment.client_id ? payment.client_id : '',
        payment.billing_type === 'company' ? `'${payment.company.tax_id}` : `'${payment.user.tax_id}`,
        payment.billing_type,
        payment.company.name,
        payment.user.last_name,
        payment.user.first_name,
        payment.amount_paid.toFixed(2),
        payment.received_at_bank_account,
        moment.utc(payment.payment_recorded).format('DD.MM.YYYY'),
        branch
       

      ];
      return arr.join('";"');
    });
  }

  if (state.loading || state.error) {
    return (
      <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
        {state.error ? state.error.message : <Spinner animation="border"/>}
      </div>
    );
  }
  return (
    <pre>
      {header}
      {list.map(item => `"${item}"\n`)}
    </pre>
  );
}

export default function RawListWrapper(props) {
  const [period, setPeriod] = useState('');
  const [firstPeriod, setFirstPeriod] = useState(null);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    fetchData({
      url: '/api/config',
      onSuccess: ({ config }) => setFirstPeriod(
        moment(config['gr']['invoice_format_v0.2.3_start_date'])
          .tz('Europe/Athens')
          .startOf('day')
      ),
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
      },
    });
  }, [keycloak]);

  const now = moment().tz('Europe/Athens');

  const months = now.diff(firstPeriod, 'months') + 1;
  const linspace = [...Array(months).keys()];

  return (
    <div style={{ padding: '10px' }}>
      <select onChange={evt => setPeriod(evt.target.value)} defaultValue=''>
        <option disabled value=''>--Select a month--</option>
        {linspace && linspace.map((el, idx) => {
          const str_date = moment(firstPeriod).add(el, 'months').format('YYYY_MM');
          return <option key={idx} value={str_date}>{str_date}</option>;
        })}
      </select>
      <ul>
        <li><b>start_date</b>, <b>end_date</b> and <b>car_license_plate</b> only exist for <b>description</b> != custom.
        </li>
        <li><b>profession</b> (aka business activity) only exists for <b>invoice_type</b> == tpi.</li>
        <li><b>invoice_date</b> or <b>cancellation_date</b> have to fall within the selected period fot invoice to be
          listed below.
        </li>
        <li><b>payment_recorded</b> determines in which month a payment is listed. Payments are ordered
          by <b>bank_date</b> which is the day when a transaction first showed up in a company bank account.
        </li>
      </ul>
      <hr/>
      {period === '' ? <p>Please select a period.</p>
        : <RawList year={parseInt(period.split('_')[0])} month={parseInt(period.split('_')[1])} method={props.method}/>}
    </div>
  );
}