import React                     from 'react';
import ReactDOM                  from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak           from "./keycloak";
import App                from './components/App';
import * as serviceWorker from './serviceWorker';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak}
                           initOptions={{ onLoad: 'login-required' }}
                           isLoadingCheck={(keycloak) => !keycloak.authenticated}
                           LoadingComponent={(<div>Loading...</div>)}>
      <App/>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
