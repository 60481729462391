import React from 'react';
import { Button, Form } from 'react-bootstrap';


export default class InvoiceItemsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice_items: [{
        description: '',
        net_amount: 0
      }]
    }

  }

  handleItemChange = idx => evt => {
    const newItems = this.state.invoice_items.map((item, sidx) => {
      if (idx !== sidx) return item;
      item[evt.target.name] = evt.target.value;
      return item;
    });

    this.setState({ invoice_items: newItems });
    this.props.handleChange(newItems);
  }

  handleAddItem = () => {
    const newItems = this.state.invoice_items.concat([{
      description: "",
      net_amount: 0
    }]);

    this.setState({ invoice_items: newItems });
    this.props.handleChange(newItems);
  }

  handleRemoveItem = idx => () => {
    const newItems = this.state.invoice_items.filter((s, sidx) => idx !== sidx);

    this.setState({ invoice_items: newItems });
    this.props.handleChange(newItems);
  }


  render() {

    return (
      <>
        {this.state.invoice_items.length < 5 ?
          <Button variant="success" onClick={this.handleAddItem}>
            Add item
          </Button>
        : null}

        {this.state.invoice_items.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <hr />
              <div>
                <Form.Group>
                  <Form.Label>Item description</Form.Label>
                  <Form.Control as="textarea" rows="3"
                    name="description"
                    value={item.description}
                    onChange={this.handleItemChange(idx)}
                    required />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Item price (excl. VAT)</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    name="net_amount"
                    value={item.net_amount}
                    onChange={this.handleItemChange(idx)}
                    required
                  />
                  <Form.Text className="text-muted">
                    Negative total amount generates credit invoice.
                  </Form.Text>
                </Form.Group>

                {idx > 0 ?
                  <Button variant="danger" onClick={this.handleRemoveItem(idx)}>
                    Remove item
                  </Button>
                  : null}
              </div>
            </React.Fragment>
          );
        })}

        <hr />
      </>
    );
  }

}